import React from "react";

import Layout from "../layout/index";

import Seo from "../components/seo/index";

import RecentPlantations  from "../components/gallery/recentplantation";

import SimpleReactLightbox from "simple-react-lightbox";

import Socialiconright from "../components/home/social-icon-right";

const Gallerypage = () => {
  return (
    <div>
      <Seo title="Green Heartfulness Run 2024 - Gallery" />
      <Layout isSticky>
       <Socialiconright />
             {
          <SimpleReactLightbox>
            <section id="recentplantation">
              <RecentPlantations />
            </section>
          </SimpleReactLightbox>
        }
      </Layout>
    </div>
  );
};

export default Gallerypage;
